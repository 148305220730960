<template>
  <div class="countdown d-flex vh-100 w-100 position-fixed">
    <div class="countdown-left d-flex flex-column align-items-center">
      <button class="countdown-burger" @click="close">
        <svg>
          <use xlink:href="@/assets/icons.svg#icon-cancel" />
        </svg>
      </button>

      <logo />

      <h2 class="countdown-heading">COMING SOON!</h2>

      <p class="countdown-para1">
        We are glad to bring you to notice of the launch of Halal Products which
        is in the next
      </p>

      <div class="countdown-timeline d-flex">
        <p>
          <span>{{ days }}</span>
          <span>Days</span>
        </p>
        <p>
          <span>{{ hours }}</span>
          <span>Hours</span>
        </p>
        <p>
          <span>{{ minutes }}</span>
          <span>Mins</span>
        </p>
        <p>
          <span>{{ seconds }}</span>
          <span>seconds</span>
        </p>
      </div>

      <p class="countdown-para2">... solving SMEs financial issues</p>
    </div>

    <div class="countdown-right d-none d-md-flex">
      <div>
        <img src="@/assets/rafiki.png" class="w-100" alt="rafiki" />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import Logo from "./Logo.vue";
import countdown from "countdown";

export default defineComponent({
  components: { Logo },
  name: "count-down",
  props: { close: Function },
  mounted() {
    this.timer = setInterval(() => {
      const timeLeft = countdown(new Date(), new Date(2022, 0, 31));

      this.days = timeLeft.months * 30 + timeLeft.days;
      this.hours = timeLeft.hours;
      this.minutes = timeLeft.minutes;
      this.seconds = timeLeft.seconds;
    }, 1000);
  },
  data() {
    const timeLeft = countdown(new Date(), new Date(2022, 0, 31));

    return {
      days: timeLeft.months * 30 + timeLeft.days,
      hours: timeLeft.hours,
      minutes: timeLeft.minutes,
      seconds: timeLeft.seconds,
      timer: 0,
    };
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
});
</script>

<style lang="scss" scoped>
.countdown {
  top: 0;
  left: 0;
  z-index: 401;

  &-burger {
    cursor: pointer;
    background-color: transparent;
    border: none;
    align-self: flex-start;
    margin-left: 3rem;
    margin-bottom: 2rem;

    & svg {
      height: 5rem;
      width: 5rem;
      fill: var(--btn-blue);
    }
  }

  &-left,
  &-right {
    flex: 0 0 50%;

    @media screen and (max-width: 48em) {
      flex: unset;
    }
  }

  &-left {
    background-color: var(--white);
    padding-top: 2rem;
    padding-bottom: 5rem;

    @media screen and (max-width: 48em) {
      width: 100%;
    }
  }

  &-right {
    background-color: #e1f5ff;
    justify-content: center;
    align-items: center;

    & div {
      width: 50rem;
      max-width: 80%;
    }
  }

  &-heading {
    margin-top: 9rem;
    margin-bottom: 4.7rem;
    color: var(--btn-blue);
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 4.3rem;
    text-align: center;
    letter-spacing: 0.005em;
  }

  &-para {
    &1 {
      margin-bottom: 4.7rem;
      font-weight: 500;
      font-size: 1.8rem;
      width: 48.9rem;
      max-width: 90%;
      line-height: 2.2rem;
      text-align: center;
      letter-spacing: 0.005em;
      color: #343439;
    }

    &2 {
      font-weight: 300;
      font-size: 2.4rem;
      line-height: 2.8rem;
      text-align: center;
      letter-spacing: -0.07em;
      color: #0066f4;
      color: var(--btn-blue);
      margin-top: 2.3rem;
    }
  }

  &-timeline {
    justify-content: space-between;
    width: 90%;

    & p {
      background-color: var(--btn-blue);
      text-align: center;
      padding: 1rem 0;
      border-radius: 0.7rem;
      color: var(--light-bg);
      margin-bottom: 0;
      flex: 0 0 22%;

      & span {
        display: block;
        font-weight: 800;
        text-align: center;
        letter-spacing: -0.055em;

        &:first-child {
          font-size: 6rem;
          line-height: 7rem;
        }
        &:last-child {
          font-size: 2.2rem;
          line-height: 2.6rem;
        }
      }
    }
  }
}
</style>
