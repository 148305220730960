<template>
  <count-down v-if="countdown" :close="countHandler" />
  <layout :download="true" :footer="true">
    <overview :countdown="countHandler" />
    <about />
    <feature />
    <be-halal />
    <alajo />
    <download-step :countdown="countHandler" />
    <society />
    <!-- <partner /> -->
  </layout>
</template>

<script>
import { defineComponent } from "vue";
// import Launch from "@/components/Launch.vue";
import About from "@/sections/About.vue";
import BeHalal from "@/sections/BeHalal.vue";
import DownloadStep from "@/sections/DownloadStep.vue";
import Feature from "@/sections/Feature.vue";
import Overview from "@/sections/Overview.vue";
import CountDown from "@/components/CountDown.vue";
// import Partner from "@/sections/Partner.vue";
import Society from "@/sections/Society.vue";
import Layout from "@/Layout/Layout.vue";
import Alajo from "@/sections/Alajo.vue";

export default defineComponent({
  name: "Home",
  components: {
    Overview,
    About,
    Feature,
    BeHalal,
    DownloadStep,
    Society,
    CountDown,
    // Partner,
    // Launch,
    Layout,
    Alajo,
  },
  data() {
    return {
      countdown: false,
      launch: true,
    };
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
  methods: {
    // launchHandler() {
    //   this.launch = !this.launch;
    // },
    countHandler() {
      this.countdown = !this.countdown;
    },
  },
});
</script>
