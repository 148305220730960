
import gsap from "gsap";
import { defineComponent } from "vue";
import Button from "../components/Button.vue";

export default defineComponent({
  name: "Overview",
  components: { Button },
  mounted() {
    gsap.to(".overview-right-bg", {
      duration: 5,
      ease: "linear",
      rotate: 360,
      repeat: -1,
      transform: "-50% -50%",
    });

    gsap.to(".overview-loan", {
      y: 10,
      duration: 1,
      ease: "linear",
      repeat: -1,
      yoyoEase: true,
      yoyo: true,
    });
  },
  props: { countdown: Function },
  data() {
    return {
      downloadsgoogle: [
        { icon: "gpl.png", head: "get it on", foot: "google play" },
      ],
      downloadsapple: [
        { icon: "al.png", head: "download on the", foot: "app store" },
      ],
      loans: [
        {
          name: "AishatYe",
          amount: "250,000",
          balance: "950,000",
          heading: "Loan Service Repayment",
          id: 1,
        },
        {
          name: "LukmanIb",
          amount: "50,000",
          balance: "750,000",
          heading: "Credit Alert",
          id: 2,
        },
        {
          name: "SusanKa",
          amount: "1,500,000",
          balance: "2,750,000",
          heading: "P2P Loan Approved!",
          id: 3,
        },
        {
          name: "DembeleUk",
          amount: "80,000",
          balance: "720,000",
          heading: "Student Loan Approved",
          id: 4,
        },
      ],
      dClass: [
        "overview-down",
        "d-flex",
        "flex-column",
        "flex-md-row",
        "mt-5",
        "justify-content-center",
        "justify-content-md-start",
        "align-items-stretch",
        "align-items-md-center",
        "gap-5",
      ].join(" "),
    };
  },
});
