
import { defineComponent } from "vue";
import FeaturesBox from "../components/FeaturesBox.vue";

export default defineComponent({
  components: { FeaturesBox },
  name: "Feature",
  data() {
    return {
      features1: [
        {
          icon: "rocket",
          heading: "Fast Transactions",
          para: "Transactions on Halal are performed under lightning speed. It literally takes seconds to confirm transactions. ",
          right: false,
        },
        {
          icon: "flash",
          heading: "Zero stress",
          para: "Easy application process with no paper work. Verification and disbursement is done at instant.",
          right: false,
        },
      ],
      features2: [
        {
          icon: "nfc",
          heading: "Peer-to-Peer Payment",
          para: "Halal user can send and receive money using QRcode, link invitation, pay by phone number, and username.",
          right: true,
        },
        {
          icon: "smiley",
          heading: "User friendly",
          para: "Halal user can send and receive money using QRcode, link invitation, pay by phone number, and username.",
          right: true,
        },
      ],
    };
  },
});
