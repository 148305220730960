
import Button from "@/components/Button.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "nav-bar",
  components: { Button },
  props: { nav: Function, launch: Function, countdown: Function },
  data() {
    return {
      links: [
        { name: "Why Halal?", link: "/about" },
        { name: "Our Culture", link: "/our-culture" },
        { name: "FAQ", link: "/faq" },
        { name: "Join as Cooperative", link: "/sign-up" },
      ],
    };
  },
});
