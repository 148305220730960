
import axios from "@/axios";
import { defineComponent } from "vue";
import Button from "./Button.vue";
import Logo from "./Logo.vue";

const pattern =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export default defineComponent({
  name: "launch",
  components: {
    Logo,
    Button,
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      message: "",
      alert: false,
      error: false,
      loading: false,
      success: false,
      formClass: [
        "launch-group d-flex flex-column justify-content-center align-items-stretch",
      ],
    };
  },
  props: { close: Function },
  methods: {
    saveEmail() {
      this.alert = false;
      this.message = "";
      this.loading = true;

      if (pattern.test(this.email))
        axios
          .post("/web/join-waiting-list", {
            fullname: this.name,
            email: this.email,
            phonenumber: this.phone,
          })
          .then(() => {
            this.message = "You have successfully subscribed";
            this.error = false;
            this.success = true;
          })
          .catch((err) => {
            console.log(err.response.data);
            this.message = err.response.data.message;
            this.error = true;
          })
          .finally(() => {
            this.alert = true;
            this.loading = false;

            setTimeout(() => {
              this.alert = false;
            }, 5000);
          });
    },
  },
});
