import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: () => import("../views/SignUp.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("../views/FAQ.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/our-culture",
    name: "our-culture",
    component: () => import("../views/GetStarted.vue"),
  },
  {
    path: "/app-terms-and-condition",
    name: "AppTermsAndCondition",
    component: () => import("../views/AppTermsAndCondition.vue"),
  },
  {
    path: "/app-privacy-policy",
    name: "AppPrivacyPolicy",
    component: () => import("../views/AppPrivacyPolicy.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
