import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24361ea5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feat" }
const _hoisted_2 = { class: "feat-box" }
const _hoisted_3 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.heading), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.para), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["feat-icon", _ctx.isRight ? 'feat-right' : ''])
    }, [
      (_openBlock(), _createElementBlock("svg", null, [
        _createElementVNode("use", {
          "xlink:href": `${require('@/assets/icons.svg')}#icon-${_ctx.icon}`
        }, null, 8, _hoisted_3)
      ]))
    ], 2)
  ]))
}