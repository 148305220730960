
import Button from "@/components/Button.vue";
import gsap from "gsap";
import { defineComponent } from "vue";

export default defineComponent({
  name: "About",
  mounted() {
    const timeline = gsap.timeline({ repeat: -1 });

    timeline.to(".about-img-1", { y: "25rem", duration: 2 });
    timeline.to(".img-roll", { rotate: 720, duration: 1 });
  },
  components: { Button },
});
