
import { defineComponent } from "vue";

export default defineComponent({
  name: "be-halal",
  data() {
    return {
      supports: [
        {
          heading: "Business Owners (Physical & Virtual Stores)",
          paragraph:
            "Halal is available for business owners who need help with raising fund for their businesses.",
          img: "1",
        },
        {
          heading: "Students",
          paragraph:
            "Halal is available for students who need an app for savings.",
          img: "2",
        },
      ],
    };
  },
});
