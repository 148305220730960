
import Button from "@/components/Button.vue";
import { defineComponent } from "vue";
import gsap from "gsap";

export default defineComponent({
  components: { Button },
  name: "download",
  data() {
    return {
      downloadsgoogle: [
        { icon: "gpl.png", head: "get it on", foot: "google play" },
      ],
      downloadsapple: [
        { icon: "al.png", head: "download on the", foot: "app store" },
      ],
    };
  },
  props: { countdown: Function },
  mounted() {
    gsap.to(".download-scaling", {
      duration: 1,
      yoyo: true,
      repeat: -1,
      scale: 1.5,
    });

    gsap.to(".download-end-img", {
      duration: 0.5,
      yoyo: true,
      repeat: -1,
      transform: "translate(-1rem, 0.5rem)",
    });
  },
});
