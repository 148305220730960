
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    type: String,
    name: String,
    iconed: Boolean,
    icon: String,
    head: String,
    foot: String,
    svg: Boolean,
    disabled: Boolean,
  },
});
