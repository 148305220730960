import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c067bafc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "d-flex flex-column align-items-start mb-0" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.iconed)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "bttn bttn-purple d-flex justify-content-center align-items-center",
        disabled: _ctx.disabled
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: require(`../assets/${_ctx.icon}`),
            alt: "icon",
            class: "w-100 h-100"
          }, null, 8, _hoisted_2)
        ]),
        _createElementVNode("p", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.head), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.foot), 1)
        ])
      ], 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: _normalizeClass(["bttn", 
      _ctx.type === 'blue' ? 'bttn-blue' : _ctx.type === 'trans' ? 'bttn-trans' : ''
    ]),
        disabled: _ctx.disabled
      }, [
        _createTextVNode(_toDisplayString(_ctx.name) + " ", 1),
        (_ctx.svg)
          ? (_openBlock(), _createElementBlock("svg", _hoisted_5, [
              _createElementVNode("use", {
                "xlink:href": `${require('@/assets/icons.svg')}#icon-${_ctx.icon}`
              }, null, 8, _hoisted_6)
            ]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_4))
}