
import { defineComponent } from "vue";

export default defineComponent({
  name: "feature-box",
  props: {
    para: String,
    heading: String,
    icon: String,
    isRight: Boolean,
  },
});
