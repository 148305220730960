<template>
  <router-view />
</template>

<style lang="scss">
@import "~bootstrap/scss/bootstrap.scss";
:root {
  --primary: #005bff;
  --white: #ffffff;
  --light-bg: #f9fcff;
  --btn-blue: #0066f4;
  --para: #444449;
  --heading: #003780;
}
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  @media screen and (max-width: 85.375em) {
    font-size: 55%;
  }
  @media screen and (max-width: 75em) {
    font-size: 50%;
  }
  @media screen and (max-width: 62em) {
    font-size: 45%;
  }
  @media screen and (max-width: 59.375em) {
    font-size: 35%;
  }
  @media screen and (max-width: 48em) {
    font-size: 40%;
  }
}
body {
  font-size: 1.6rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  position: relative;
  background-color: var(--white);
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.heading {
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 140%;
  letter-spacing: -0.065em;
  color: var(--btn-blue);

  &-2 {
    font-weight: 700;
    font-size: 6.4rem;
    line-height: 6.4rem;
    letter-spacing: -0.05em;
    color: var(--heading);
    text-align: center;
  }
}
.para {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 135%;
  letter-spacing: -0.015em;
  color: var(--heading);
  margin-bottom: 4rem;
}
</style>
