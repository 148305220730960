
import Button from "@/components/Button.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { Button },
  name: "download-step",
  props: { countdown: Function },
  data() {
    return {
      downloadsgoogle: [
        { icon: "gpl.png", head: "get it on", foot: "google play" },
      ],
      downloadsapple: [
        { icon: "al.png", head: "download on the", foot: "app store" },
      ],
      steps: [
        {
          id: 2,
          text: "Create Account",
          text2: "Sign up by creating account with your phone number.",
          icon: "user-circle",
        },
        {
          id: 3,
          text: "Save Money",
          text2: "Select your saving plan and start saving.",
          icon: "contact-book",
        },
        {
          id: 4,
          text: "Join Ajo",
          text2: "Get access to group savings (Ajo, Esusu) without stress.",
          icon: "join-app",
        },
      ],
      dssClass: [
        "step-down",
        "d-flex",
        "flex-column flex-md-row",
        "justify-content-center",
        // "justify-content-md-start",
        "align-items-center",
      ].join(" "),
      dsClass: [
        "step-box",
        "d-flex",
        "flex-column",
        "align-items-center",
        // "justify-content-between",
      ].join(" "),
    };
  },
});
