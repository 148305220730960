
import { defineComponent } from "vue";
import Button from "../components/Button.vue";
import Logo from "../components/Logo.vue";

export default defineComponent({
  name: "Header",
  props: { nav: Function, countdown: Function, launch: Function },
  components: {
    Logo,
    Button,
  },
  data() {
    return {
      links: [
        { name: "Why Halal?", link: "/about" },
        { name: "Our Culture", link: "/our-culture" },
        { name: "FAQ", link: "/faq" },
        { name: "Join as Cooperative", link: "/sign-up" },
      ],
    };
  },
});
