
import { defineComponent } from "vue";

export default defineComponent({
  name: "alajo",
  props: {},
  data() {
    return {
      lists: [
        {
          heading: "Halal Ajo for Indivduals",
          items: [
            "Move your existing ajo group to Halal and keep saving with your friends with up to 3% cash back when you are the last to withdraw",
            "Create an ajo group on Halal and start saving instantly with 2k administrative reward for group saving upto 200k monthly",
            "1k referral bonus credit to your ajo wallet on first cashout of users you refer.",
            "Automate weekly or monthly savings in your Halal vault so you don’t miss your contribtuion",
          ],
        },
        {
          heading: "Halal Ajo for Business",
          items: [
            "Get access to Zero-interest loan when you contribute with us  ",
            "Get up to 3% commission when your staffs  contributes consistently for 3 months",
            "Forget fraud or cases of loss by thrift agent, your contributions are secured and insured",
          ],
        },
      ],
    };
  },
});
