
import Logo from "@/components/Logo.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { Logo },
  name: "Footer",
  data() {
    return {
      socials: [
        { link: "#", icon: "twitter" },
        { link: "#", icon: "linkedin" },
        { link: "#", icon: "facebook" },
      ],
      list1: [
        { link: "#", name: "Company" },
        { link: "/about", name: "About Us" },
        { link: "#", name: "Press" },
        { link: "#", name: "Careers" },
        { link: "#", name: "Terms" },
      ],
      list2: [
        { link: "#", name: "Resources" },
        { link: "#", name: "Customer Stories" },
        { link: "#", name: "Media" },
        { link: "/faq", name: "FAQs" },
        { link: "#", name: "Security" },
      ],
      list3: [
        { link: "/contact", name: "Contact" },
        { link: "#", name: "+234 810 283 7968", icon: "phone" },
        { link: "#", name: "support@withhalal.com", icon: "mail" },
      ],
      footerClass: [
        "footer-top",
        "d-flex",
        "flex-column flex-md-row",
        "align-items-start",
      ].join(" "),
    };
  },
});
