
import Header from "@/sections/Header.vue";
import Footer from "@/sections/Footer.vue";
import Launch from "@/components/Launch.vue";
import NavBar from "@/sections/NavBar.vue";
import Download from "@/sections/Download.vue";
import CountDown from "@/components/CountDown.vue";
import { defineComponent } from "vue";
import gsap from "gsap";

export default defineComponent({
  components: {
    Header,
    Footer,
    NavBar,
    Download,
    CountDown,
    Launch,
  },
  props: { footer: Boolean, download: Boolean },
  data() {
    return {
      nav: false,
      countdown: false,
      launch: false,
    };
  },
  mounted() {
    gsap.to(".layout-btn", {
      repeat: -1,
      duration: 0.5,
      y: -10,
      yoyo: true,
      yoyoEase: true,
    });

    window.scrollTo({ top: 0 });
  },
  methods: {
    navHandler() {
      this.nav = !this.nav;
    },
    countHandler() {
      this.countdown = !this.countdown;
    },
    launchHandler() {
      this.launch = !this.launch;
    },
  },
});
